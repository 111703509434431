<template>
  <b-card>
    <validation-observer #default="{handleSubmit}">
      <b-form @submit.prevent>
        <suggestion-inputs
          :suggestion="suggestion"
          :disabled="$attrs.disabled"
        />
        <slot name="additional-buttons" />

        <b-row>
          <b-col>
            <back />
          </b-col>
          <b-col class="text-right">
            <submit-button
              v-if="!$attrs.disabled"
              btn-class="ml-auto"
              v-bind="{submit,handleSubmit}"
            />
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import Back from '../common/FormInputs/Back.vue'
import SubmitButton from '../common/FormInputs/SubmitButton.vue'
import SuggestionInputs from './SuggestionInputs.vue'

export default {
  name: 'SuggestionForm',
  components: { SubmitButton, SuggestionInputs, Back },
  props: {
    submit: { type: Function, default: () => {} },
    suggestion: { type: Object, default: () => ({}) },
  },
}
</script>

<style>

</style>
