<template>
  <fieldset :disabled="$attrs.disabled">
    <div class="mb-2">
      <strong>Suggested By:</strong> <b-link :to="{name:'admin-mobile-users-view',params:{id:suggestUserID}}">
        {{ suggestUser }}
      </b-link>
    </div>
    <b-form-row>
      <!-- Masjed Name -->
      <b-col md="6">
        <text-input
          id="name"
          name="Name"
          rules="min:3|max:250"
          :text.sync="suggestion.name"
        />
      </b-col>
      <!-- Subclass Select -->
      <b-col
        v-if="isEditForm"
        md="6"
      >
        <subclass-select
          :disabled="$attrs.disabled"
          label="Subclassification"
          multiple
          :filter="{type:suggestionClass,status:'active'}"
          :subclass.sync="suggestion.subClassifications_id"
        />
      </b-col>
      <b-col
        v-else
        md="6"
      >
        <b-form-group
          label="Subclassifications"
          label-for="subclass"
        >
          <ul id="subclass">
            <li
              v-for="(subclass,index) in subclassNames"
              :key="index"
            >
              {{ subclass }}
            </li>
          </ul>
        </b-form-group>
      </b-col>
      <!-- Description -->
      <b-col md="6">
        <text-input
          id="suggestion-description"
          name="Description"
          placeholder="Brief about place"
          :text.sync="suggestion.about"
        />
      </b-col>
      <!-- Location -->
      <b-col
        cols="12"
        class="border p-2 my-2"
      >
        <location-inputs :details="suggestion.address" />
      </b-col>
      <!-- Media -->
      <b-col cols="12">
        <b-col cols="12">
          <upload-image
            v-if="!$attrs.disabled"
            id="inpo-media_files"
            label="Media"
            rules="size:5000"
            multiple
            :images.sync="suggestion.media_files"
          />
        </b-col>
        <b-col cols="12">
          <images-base-64-preview
            :disabled="$attrs.disabled"
            file-id="inpo-media_files"
            :images.sync="suggestion.media_files"
          />
        </b-col>
      </b-col>
      <!-- Email -->
      <b-col md="6">
        <text-input
          id="suggestion-email"
          name="Email"
          type="email"
          :text.sync="suggestion.user.email"
        />
      </b-col>

      <!-- Business Email -->
      <b-col md="6">
        <text-input
          id="suggestion-business-email"
          name="Business Email"
          :rules="`${suggestion.set_user_as_organizer?'required':''}|email`"
          type="email"
          :text.sync="suggestion.business_email"
        />
      </b-col>
      <!-- Facebook Link -->
      <b-col md="6">
        <text-input
          id="suggestion-facebook"
          name="Facebook Link"
          rules="facebook-url"
          :text.sync="suggestion.social_facebook"
        />
      </b-col>
      <!-- Twitter Link -->
      <b-col md="6">
        <text-input
          id="suggestion-twitter"
          name="Twitter Link"
          rules="twitter-url"
          :text.sync="suggestion.social_twitter"
        />
      </b-col>
      <!-- Instagram Link -->
      <b-col md="6">
        <text-input
          id="suggestion-instagram"
          name="Instagram Link"
          rules="instagram-url"
          :text.sync="suggestion.social_instagram"
        />
      </b-col>
      <!-- Website Link -->
      <b-col md="6">
        <text-input
          id="suggestion-website"
          name="Website Link"
          rules="url"
          :text.sync="suggestion.website"
        />
      </b-col>
      <!-- Work for the suggested org -->
      <b-col
        cols="12"
        class="my-2"
      >
        <b-checkbox
          v-model="suggestion.isEmployee"
          disabled
        >
          Work for the suggested org
        </b-checkbox>

        <b-checkbox
          v-model="suggestion.set_user_as_organizer"
          class="my-2"
        >
          Set user as organization admin
        </b-checkbox>
      </b-col>
    </b-form-row>
  </fieldset>
</template>

<script>
import ImagesBase64Preview from '../common/FormInputs/ImagesBase64Preview.vue'
import LocationInputs from '../common/FormInputs/LocationInputs.vue'
import SubclassSelect from '../common/FormInputs/SubclassSelect.vue'
import TextInput from '../common/FormInputs/TextInput.vue'
import UploadImage from '../common/FormInputs/UploadImage.vue'

export default {
  name: 'SuggestionInputs',
  components: {
    TextInput,
    LocationInputs,
    SubclassSelect,
    ImagesBase64Preview,
    UploadImage,
  },
  props: {
    suggestion: { type: Object, default: () => ({}) },
  },
  computed: {
    suggestionClass() {
      return this.suggestion?.classificationsNames?.[0]
    },
    isEditForm() {
      return !!this.$route.params.id
    },
    subclassNames() {
      return this.suggestion.subclassifications?.split(' ,')
    },
    suggestUser() {
      return `${this.suggestion.user?.profile?.first_name} ${this.suggestion.user?.profile?.last_name}`
    },
    suggestUserID() {
      return this.suggestion.user?.profile?.id
    },
  },
}
</script>

<style>

</style>
