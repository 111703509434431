<template>
  <custom-vue-select
    id="subclass"
    label="Subclass"
    placeholder="Select Sub Classification"
    :rules="rules"
    :value-reducer="option=>option.id"
    :options="options"
    :multiple="$attrs.multiple"
    :close-on-select="!$attrs.multiple"
    :disabled="$attrs.disabled"
    :selected.sync="subclassProxy"
    text-field="name"
  />
</template>
<script>
import CustomVueSelect from './CustomVueSelect.vue'

export default {
  name: 'SubclassSelect',
  components: {
    CustomVueSelect,
  },
  props: {
    subclass: { type: [Number, Array], default: null },
    classId: { type: [Number, Array], default: null },
    filter: { type: Object, default: () => ({}) },
    rules: { type: String, default: '' },
  },
  data() {
    return {
      options: [],
    }
  },
  computed: {
    subclassProxy: {
      get() {
        return this.subclass
      },
      set(value) {
        this.$emit('update:subclass', value)
      },
    },
  },
  created() {
    this.getSubclasses()
  },
  methods: {
    getSubclasses() {
      this.$entities.get('internalops/sub-classifications?paginated=false', {
        params: {
          ...this.filter,
          classification: this.classId,
        },
      }).then(res => {
        this.options = res.data.data
      })
    },
  },
}
</script>
<style lang="scss">
</style>
